import * as React from "react";
import { ModuleItemType } from "../../../assets/Types/AcfGroupTypes/ModuleContainer.type";
import styles from "./IconTextCard.module.scss";

const ModuleItem: React.FC<{
  iconTextProps: ModuleItemType;
  index?: number;
}> = ({ iconTextProps, index }) => (
  <div
    data-testid="Div:ModuleItem"
    key={index}
    className={styles.switchingItem}
  >
    <div
      data-testid="div:container:ModuleItem"
      className={styles.switchingIcon}
      style={{
        backgroundImage: `url('${iconTextProps.icon.mediaItemUrl}')`,
      }}
    />
    <div className={styles.textContainer}>
      <p className={styles.title}>{iconTextProps.title}</p>
      <p className={styles.description}>
        <span dangerouslySetInnerHTML={{ __html: iconTextProps.content }} />
      </p>
    </div>
  </div>
);

export default ModuleItem;
