import * as React from "react";
import { camelCase } from "camel-case";
import IconTextCard from "../../components/Cards/IconTextCard/IconTextCard";
import { ModuleContainerType } from "../../assets/Types/AcfGroupTypes/ModuleContainer.type";

import styles from "./ModulePContainer.module.scss";

type ModulePContainerProps = {
  moduleProps: ModuleContainerType;
  background?: string;
  id?: string | null;
};

const ModulePContainer: React.FC<ModulePContainerProps> = ({
  background = "#ffffff",
  moduleProps,
  id,
}) => {
  const backgroundClass = background
    ? `${camelCase(`background ${background}`)}`
    : ``;

  return (
    <>
      {id && <span id={id} className={styles.initial}></span>}
      <section className={`pad-top--large ${backgroundClass}`}>
        <div className={`full-width`}>
          <div className={styles.textAlignment}>
            <h2 data-testid="H1:title">{moduleProps.title}</h2>
            <p>
              <span dangerouslySetInnerHTML={{ __html: moduleProps.subCopy }} />
            </p>
          </div>
          <div className={styles.switchingGrid}>
            {moduleProps.moduleItems.map((item, index) => (
              <IconTextCard index={index} iconTextProps={item} key={index} />
            ))}
            <div className={styles.switchingGridLine} />
          </div>
        </div>
      </section>
    </>
  );
};

export default ModulePContainer;
