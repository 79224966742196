import * as React from "react";
import { CtaBannerSmallProps } from "../../../assets/Types/AcfGroupTypes/CtaBanner.type";
import PrimaryButtonWithArrow from "../../Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";
import styles from "./CtaBannerSmall.module.scss";
import setRouteName from "../../../utils/getLocationName";

const CtaBannerSmall: React.FC<CtaBannerSmallProps> = ({
  CtaBannerProp,
  className = "",
}) => {
  const routeName = setRouteName();
  return (
    <section
      data-testid="section:CtaBannerSmall"
      className={`full-width pad-top--large ${className}`}
    >
      <div className={`${styles.ctaModule} ${styles.borderLight}`}>
        <div className={styles.textSide}>
          <h3>{CtaBannerProp.ctaBannerSmallTitle}</h3>
          <span
            dangerouslySetInnerHTML={{
              __html: CtaBannerProp.ctaBannerSmallSubcopy,
            }}
          />
        </div>
        <div>
          <PrimaryButtonWithArrow
            buttonText={CtaBannerProp.ctaBannerSmallText}
            buttonLink={CtaBannerProp.ctaBannerSmallLink}
            data-click-type="sign_up_cta"
            data-click-text={CtaBannerProp.ctaBannerSmallText}
            data-click-location={`${routeName}-footer`}
          />
        </div>
      </div>
    </section>
  );
};

export default CtaBannerSmall;
