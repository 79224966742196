import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import HeroImagePContainer from "../../containers/HeroImagePContainer/HeroImagePContainer";
import ModulePContainer from "../../containers/ModulePContainer/ModulePContainer";
import CtaBannerSmall from "../../components/Banners/CtaBannerSmall/CtaBannerSmall";
import { HeroImagePType } from "../../assets/Types/AcfGroupTypes/HeroImageP.type";
import { ModuleContainerType } from "../../assets/Types/AcfGroupTypes/ModuleContainer.type";
import { CtaBannerType } from "../../assets/Types/AcfGroupTypes/CtaBanner.type";
import { FeaturesModulePType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleP/FeaturesModuleP.type";
import FeaturesModulePContainer from "../../containers/FeaturesModulePContainer/FeaturesModulePContainer";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import useOnUserIntent from "../../hooks/useOnUserIntent";
import { deIndexSeo } from "../../utils/deIndexSeo";
import { enableSmoothScroll } from "@utils/enableSmoothScroll";

type SwitchingProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      heroImageP: HeroImagePType;
      switchingModuleP: ModuleContainerType;
      ctaBannerSmallP: CtaBannerType;
      featuresModuleP: FeaturesModulePType;
      userIntent: Queries.WpSwitchingTemplate_Userintent;
    };
    title: string;
  };
};

const SwitchingPage: React.FC<PageProps<SwitchingProps>> = ({ data }) => {
  enableSmoothScroll(true);
  const { wpPage } = data;
  const {
    template: {
      heroImageP,
      switchingModuleP,
      ctaBannerSmallP,
      featuresModuleP,
      userIntent,
    },
    title,
  } = wpPage;

  useOnUserIntent(userIntent);

  return (
    <MainLayout hasFooterLargeCta={true} backgroundColor="blue">
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <HeroImagePContainer background="blue" heroProps={heroImageP} />
      <ModulePContainer moduleProps={switchingModuleP} id="switching-module" />
      <CtaBannerSmall
        background="blue"
        CtaBannerProp={ctaBannerSmallP}
        className="pad-bottom--large"
      />
      <FeaturesModulePContainer
        props={featuresModuleP}
        page={title}
        withPaddingB={false}
        withPaddingT={false}
      />
    </MainLayout>
  );
};

export default SwitchingPage;

export const query = graphql`
  query SwitchingTemplateQuery(
    $id: String
    $featureModuleImageWidth: Int = 800
  ) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      id
      template {
        templateName
        ... on WpSwitchingTemplate {
          ...SwitchingUserIntentFragment
          templateName
          featuresModuleP {
            featuresModuleSubcopy
            featuresModuleTitle
            featuresModuleList {
              featuresModuleAssetToggle
              featuresModuleItemBadge
              featuresModuleItemBadgeColor
              featuresModuleItemCta
              featuresModuleItemCtaAriaText
              featuresModuleItemCtaText
              featuresModuleItemImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: $featureModuleImageWidth
                      placeholder: BLURRED
                      formats: [WEBP, JPG, AUTO]
                    )
                  }
                }
              }
              featuresModuleItemQuote
              featuresModuleItemQuoteAuthor
              featuresModuleItemSubcopy
              featuresModuleItemSubcopySecondary
              featuresModuleItemTitle
              featuresModuleItemTitleColor
              featuresModuleItemTitleSecondary
              featuresModuleItemTitleSecondaryColor
              featuresModuleItemVideo {
                altText
                mediaItemUrl
              }
              featuresModuleItemVideoImage {
                altText
                mediaItemUrl
              }
            }
          }
          heroImageP {
            title: heroImageTitle
            subCopy: heroImageSubcopy
            videoId: heroImageVideoId
            videoImg: heroImageImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [WEBP, JPG, AUTO]
                    width: 800
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          ctaBannerSmallP {
            ctaBannerType
            ctaBannerSmallTitle
            ctaBannerSmallText
            ctaBannerSmallSubcopy
            ctaBannerSmallLink
          }
          switchingModuleP {
            title: switchingModuleTitle
            subCopy: switchingModuleSubcopy
            moduleItems: switchingModuleItems {
              title
              content
              icon {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`;
